<template>
  <div class="remoteDoorOpening">
    <div class="item-t" @click="isShowPicker = true">
      <div class="item-l">{{ address }}</div>
      <img
        src="./img/arrow.png"
        :class="[{ downArrow: isShowPicker }]"
        alt=""
      />
    </div>
    <div class="item-c" v-if="iframeSrc">
      <iframe
        :src="iframeSrc"
        frameborder="0"
        name="myFrameName"
        id="myFrameId"
      >
      </iframe>
    </div>
    <div class="item-b">
      <div class="top" @click="handelClickOpenDoor">
        <img v-if="!isOpen" src="./img/openDoor.png" alt="" />
        <img v-else src="./img/alreadyOpening.png" alt="" />
      </div>
      <div class="bottom">{{ isOpen ? "门已开" : "立即远程开门" }}</div>
    </div>
    <v-picker
      :columns="OpenDoorList"
      :valueShow="isShowPicker"
      :valueKey="valueKey"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-picker>
    <v-dialog
      v-model="isDialog"
      title="提示"
      @confirm="confirm"
      confirmButtonColor="#3680FF"
    >
      <p
        style="
          text-align: center;
          line-height: 42px;
          margin: 26px 0 36px 0;
          color: rgba(0, 0, 0, 0.5);
        "
      >
        管理员是否确认进行远程开门？
      </p>
    </v-dialog>
  </div>
</template>

<script>
import { getOpenDoorList, openDoorUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "remoteDoorOpening",
  components: {},
  props: {},
  data() {
    return {
      isOpen: false, //控制是否正在开门
      address: "", //设备
      isShowPicker: false, //picker显示or隐藏
      isDialog: false, //弹窗
      valueKey: "hotspotName",
      iframeSrc: "", //地址
      deviceId: "", //设备id
      OpenDoorList: [], //开门数据
      openTimeOut: null, //延时器
    };
  },
  async created() {
    await this.getOpenDoorList();
  },
  mounted() {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  methods: {
    //获取开门列表
    getOpenDoorList() {
      this.$axios.get(getOpenDoorList).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          this.address = data[0].hotspotName;
          this.deviceId = data[0].deviceId;
          this.iframeSrc = `https://wjk.zhuneng.cn/d3mdoc/#/iframe/wjk?id=${this.deviceId}`;
          this.OpenDoorList = data;
        }
      });
    },
    handelClickOpenDoor() {
      if (this.isOpen) {
        return;
      }
      this.isDialog = true;
    },
    //确认
    confirm() {
      const params = {
        deviceId: this.deviceId,
        userId: this.userId,
      };
      this.$axios.post(openDoorUrl, this.$qs.stringify(params)).then((res) => {
        if (res.code == 200) {
          this.isOpen = true;
          this.openTimeOut = setTimeout(() => {
            this.isOpen = false;
          }, 3000);
        }
        this.$toast(res.msg);
        this.isDialog = false;
      });
    },
    confirmPicker(value) {
      let iframe = document.getElementById("myFrameId");
      iframe.contentWindow.postMessage(
        {
          type: "f_select",
          data: {
            device_id: value.deviceId,
          },
        },
        "*"
      );
      this.address = value.hotspotName;
      this.deviceId = value.deviceId;
      this.isShowPicker = false;
    },
    cancel() {
      this.isShowPicker = false;
    },
    clickOverlay() {
      this.isShowPicker = false;
    },
  },
  beforeDestroy() {
    clearTimeout(this.openTimeOut);
  },
};
</script>

<style scoped lang="less">
.remoteDoorOpening {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 26px 28px;
  background: #ffffff;
  ::v-deep .v-dialog {
    .van-dialog__header {
      height: 44px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 44px;
    }
  }
  .item-t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
    border-radius: 16px;
    padding: 26px 46px;
    .item-l {
      flex: 1;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }

    img {
      width: 26px;
      height: 12px;
      &.downArrow {
        transform: rotate(180deg);
      }
    }
  }
  .item-c {
    margin: 30px 0 220px 0;
    width: 100%;
    height: 330px;
    overflow: hidden;
    border-radius: 16px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .item-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 370px;
      height: 370px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom {
      margin-top: 38px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }
  }
}
</style>
